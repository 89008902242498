import {
  Row, Col, Input 
} from "reactstrap";
import { memo, useState } from "react";
const AccountForm = ({ type, accIdx, agreementName, memberIdx, products, markups, membersItems, changeMembersItem, changeMembersProductItem, changeMarkupItem }) => {
  const [membersItemsState, setMembersItemsState] = useState(membersItems.current);
  return (
    <div>
      <Row className="w-100 my-1 align-items-center my-0 py-2 border border-primary bg-primary">
        <Col md="3">
          {agreementName}
        </Col>
        <Col>
          <Input
            name={`members[${memberIdx}].values[${accIdx}].rebate`}
            value={`${membersItemsState[memberIdx]?.values?.[accIdx]?.rebate ?? 0}`}
            type="number"
            onChange={(e) => {
              changeMembersItem(memberIdx, "rebate", e.target.value, accIdx);
              setMembersItemsState((state) => {
                const newState = [...state];
                newState[memberIdx].values[accIdx].rebate = e.target.value;
                for (const product in newState[memberIdx].values[accIdx].products) {
                  newState[memberIdx].values[accIdx].products[product].rebate =  e.target.value;
                  changeMembersProductItem(memberIdx, "rebate", e.target.value, accIdx, product?.toLowerCase());
                }
                return newState;
              });
            }}
          /> 
        </Col>
        <Col>
          <Input
            name={`members[${memberIdx}].values[${accIdx}].commission`}
            value={`${membersItemsState[memberIdx]?.values?.[accIdx]?.commission ?? 0}`}
            type="number"
            onChange={(e) => {
              changeMembersItem(memberIdx, "commission", e.target.value, accIdx);
              setMembersItemsState((state) => {
                const newState = [...state];
                newState[memberIdx].values[accIdx].commission = e.target.value;
                for (const product in newState[memberIdx].values[accIdx].products) {
                  newState[memberIdx].values[accIdx].products[product].commission =  e.target.value;
                  changeMembersProductItem(memberIdx, "commission", e.target.value, accIdx, product?.toLowerCase());
                }
                return newState;
              });
            }}
          /> 
        </Col>
      </Row>
      {Object.keys(type?.products ?? {})?.map((prod, prodIdx) => (
        <Row
          key={prodIdx}
          className="my-1 align-items-center"
        > 
          <Col md="3">{
            products.find((p) => p?.toLowerCase() === prod?.toLowerCase())
          }</Col>
          <Col>
            <Input
              type="number"
              name={`members[${memberIdx}].values[${accIdx}].products[${prod}].rebate`}
              value={`${membersItemsState?.[memberIdx]?.values?.[accIdx]?.products?.[`${prod}`]?.rebate ?? 0}`}
              onChange={(e) => {
                changeMembersProductItem(memberIdx, "rebate", e.target.value, accIdx, prod?.toLowerCase());
                setMembersItemsState((state) => {
                  const newState = [...state];
                  newState[memberIdx].values[accIdx].products[prod].rebate = e.target.value;
                  return newState;
                });
              }}
            ></Input>
          </Col>
          <Col>
            <Input
              type="number"
              name={`members[${memberIdx}].values[${accIdx}].products[${prod}].commission`}
              value={`${membersItems.current?.[memberIdx]?.values?.[accIdx]?.products?.[`${prod}`]?.commission ?? 0}`}
              onChange={(e) => {
                changeMembersProductItem(memberIdx, "commission", e.target.value, accIdx, prod?.toLowerCase());
                setMembersItemsState((state) => {
                  const newState = [...state];
                  newState[memberIdx].values[accIdx].products[prod].commission = e.target.value;
                  return newState;
                });
              }}
            ></Input>
          </Col>
        </Row>
      ))}
      <Row>
        <Col md="3">Markup</Col>
        <Col>
          <Input
            className="mb-3"
            type="select"
            name={`members[${memberIdx}].values[${accIdx}].markup`}
            onChange={(e) => {
              changeMarkupItem(memberIdx, "markup", e.target.value, accIdx);
              setMembersItemsState((state) => {
                const newState = [...state];
                newState[memberIdx].values[accIdx].markup = e.target.value;
                return newState;
              });
            }}
          >
            {(markups || []).map((obj, index) => <option key={index}>
              {obj}
            </option>
            )}
          </Input>
        </Col>
      </Row>
    </div>
  );
};
export default memo(AccountForm);
import React, {
  memo,
  useEffect,
  useReducer,
  useRef,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Input,
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Alert,
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import { fetchIbParents } from "store/client/actions";
import { createSharedIbAgreement } from "store/actions";
import { FixedSizeList as List } from "react-window";
import InvestorForm from "./InvestorForm";
import AccountForm from "./AccountForm";

const getAccountType = (allAccountTypes, accountTypeId) => {
  return allAccountTypes.find((acc) => acc._id === accountTypeId);
};

const initState = {
  title: "",
  totals: [],
  members: [],
};

const AGGREMENT_TOTAL_TYPE = (ele = {}) => {
  return {
    accountTypeId: ele?.accountTypeId,
    rebate: "0",
    commission: "0",
  };
};

const AgreementTotals = ({ type, accIdx, agreementName, aggrementTotalsItem, changeAggrementItem }) => {
  const [aggrementTotalsItemState, setAggrementTotalsItemState] = useState(aggrementTotalsItem);
  return (<Row key={type._id} className="my-1 align-items-center">
    <Col md="3">
      {agreementName}
      {/* <AvField
        name={`totals[${accIdx}].accountTypeId`}
        value={type.accountTypeId}
        type="hidden"
      /> */}
    </Col>
    <Col>
      {/* <AvField
        name={`totals[${accIdx}].rebate`}
        // value={accIdx.rebate}
        value={aggrementTotalsItem[accIdx]?.rebate ?? "0"}
        bsSize="sm"
        type="text"
        errorMessage={t("Invalid Rebate value!")}
        validate={{
          required: { value: true },
          min: { value: 0 },
        }}
        onChange={(e) => {
          if (!isNaN(e.target.value)) {
            changeAggrementItem(accIdx, "rebate", `${e.target.value}`);
            dispatcher({
              type: "UPDATE_TOTAL_REBATE",
              payload: {
                accountTypeId: type.accountTypeId,
                rebate: `${e.target.value}`,
              },
            });
          }
        }}
      /> */}
      <Input
        name={`totals[${accIdx}].rebate`}
        value={aggrementTotalsItemState[accIdx]?.rebate ?? "0"}
        type="number"
        onChange={(e) => {
          if (!isNaN(e.target.value)) {
            changeAggrementItem(accIdx, "rebate", `${e.target.value}`);
            setAggrementTotalsItemState((state) => {
              const newState = [...state];
              newState[accIdx].rebate = e.target.value;
              return newState;
            });
          }
        }}
      >
      </Input>
    </Col>
    <Col>
      {/* <AvField
        name={`totals[${accIdx}].commission`}
        bsSize="sm"
        type="text"
        // value={accIdx.commission}
        value={aggrementTotalsItem[accIdx]?.commission ?? "0"}
        errorMessage={t("Invalid Commission value!")}
        validate={{
          required: { value: true },
          min: { value: 0 },
        }}
        onChange={(e) => {
          if (!isNaN(e.target.value)) {
            changeAggrementItem(accIdx, "commission", `${e.target.value}`);
            dispatcher({
              type: "UPDATE_TOTAL_COMMISSION",
              payload: {
                accountTypeId: type.accountTypeId,
                commission: `${e.target.value}`,
              },
            });
          }
        }}
      /> */}
      <Input
        name={`totals[${accIdx}].commission`}
        value={aggrementTotalsItemState[accIdx]?.commission ?? "0"}
        type="number"
        onChange={(e) => {
          if (!isNaN(e.target.value)) {
            changeAggrementItem(accIdx, "commission", `${e.target.value}`);
            setAggrementTotalsItemState((state) => {
              const newState = [...state];
              newState[accIdx].commission = e.target.value;
              return newState;
            });
          }
        }}
      >
      </Input>
    </Col>
  </Row>);
};

const MemoizedAgreementTotals = memo(AgreementTotals);

const RowAggreementTotalsItem = ({ data, index, style }) => {
  const { allAccountTypes, totals, dispatcher, aggrementTotalsItem, changeAggrementItem } = data;
  const type = totals[index];
  return (<div style={{
    ...style,
    width: "97%",
  }}>
    {
      <MemoizedAgreementTotals type={type} accIdx={index} dispatcher={dispatcher}
        aggrementTotalsItem={aggrementTotalsItem} changeAggrementItem={changeAggrementItem} totals={totals}
        agreementName={getAccountType(allAccountTypes, type.accountTypeId)?.title}
      />
    }
  </div>);
};

const RowMemberValueItem = ({ data, member, memberIdx, markups, products, membersItems, changeMembersItem, changeMembersProductItem }) => {
  const { allAccountTypes, dispatcher } = data?.data;
  const value = member?.values?.[data?.index];
  return (<div style={{
    ...data?.style,
    width: "97%",
  }}>
    {
      (getAccountType(allAccountTypes, value?.accountTypeId))?.type === "INVESTOR" ?
        <InvestorForm
          type={value}
          accIdx={data?.index}
          dispatcher={dispatcher}
          agreementName={getAccountType(allAccountTypes, value?.accountTypeId)?.title}
          memberIdx={memberIdx}
          member={member}
          membersItems={membersItems}
          changeMembersItem={changeMembersItem}
          changeMembersProductItem={changeMembersProductItem}
        />
        :
        <AccountForm
          type={value}
          accIdx={data?.index}
          dispatcher={dispatcher}
          agreementName={getAccountType(allAccountTypes, value?.accountTypeId)?.title}
          memberIdx={memberIdx}
          member={member}
          products={products}
          markups={markups}
          membersItems={membersItems}
          changeMembersItem={changeMembersItem}
          changeMembersProductItem={changeMembersProductItem}
        />
    }
  </div>);
};
const RowMemberValueItemMemo = memo(RowMemberValueItem);

const AddSharedIbModal = ({
  accountTypes: allAccountTypes,
  products,
  clientId,
  markups,
  show,
  toggle
}) => {
  const [isAgreementValid, setIsAgreementValid] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState("");
  const [submissionFailure, setSubmissionFailure] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const aggrementTotalsItem = useRef([]);
  const membersItems = useRef([]);
  const { parents } = useSelector(
    (state) => state.clientReducer?.clientDetails
  );
  const { submitting, clearingCounter } = useSelector((state) => state.ibAgreements);

  const [payload, dispatcher] = useReducer((state, action) => {
    switch (action.type) {
      case "RESET":
        aggrementTotalsItem.current = [];
        membersItems.current = [];
        return initState;
      case "INIT":
        return {
          ...action.payload,
        };
      case "UPDATE_TITLE":
        return {
          ...state,
          title: action.payload,
        };
      case "INIT_TOTALS":
        return {
          ...state,
          totals: action.payload,
        };
      case "UPDATE_TOTAL_REBATE":
        const index = state.totals.findIndex(        //eslint-disable-line
          (total) => total.accountTypeId === action.payload.accountTypeId
        );
        if (index !== -1) {
          const updatedTotals = JSON.parse(JSON.stringify(state.totals));

          updatedTotals[index] = {
            ...updatedTotals[index],
            rebate: parseFloat(action.payload.rebate),
          };
          return {
            ...state,
            totals: updatedTotals,
          };
        }
        return state;
      case "UPDATE_TOTAL_COMMISSION":
        return {
          ...state,
          totals: state.totals.map((total) => {
            if (total.accountTypeId === action.payload.accountTypeId) {
              return {
                ...total,
                commission: parseFloat(action.payload.commission),
              };
            }
            return total;
          }),
        };
      case "INIT_MEMBERS":
        return {
          ...state,
          members: action.payload,
        };
      case "UPDATE_MEMBER_REBATE_TOTAL":
        return {
          ...state,
          members: state.members.map((member) => {
            if (member.customerId === action.payload.customerId) {
              return {
                ...member,
                values: member.values.map((val) => {
                  if (val.accountTypeId === action.payload.accountTypeId) {
                    // update the products as well to this value
                    return {
                      ...val,
                      rebate: parseFloat(action.payload.rebate),
                      // products: calculateProducts(
                      //   val?.products,
                      //   null,
                      //   "rebate",
                      //   parseFloat(action.payload.rebate)
                      // ),
                    };
                  }
                  return val;
                }),
              };
            }
            return member;
          }),
        };
      case "UPDATE_MEMBER_COMMISSION_TOTAL":
        return {
          ...state,
          members: state.members.map((member) => {
            if (member.customerId === action.payload.customerId) {
              return {
                ...member,
                values: member.values.map((val) => {
                  if (val.accountTypeId === action.payload.accountTypeId) {
                    return {
                      ...val,
                      commission: parseFloat(action.payload.commission),
                      // products: calculateProducts(
                      //   val?.products,
                      //   null,
                      //   "commission",
                      //   parseFloat(action.payload.commission)
                      // ),
                    };
                  }
                  return val;
                }),
              };
            }
            return member;
          }),
        };
      case "UPDATE_MEMBER_REBATE_PRODUCT":
        return {
          ...state,
          members: state.members.map((member) => {
            if (member.customerId === action.payload.customerId) {
              return {
                ...member,
                values: member.values.map((val) => {
                  if (val.accountTypeId === action.payload.accountTypeId) {
                    return {
                      ...val,
                      // products: calculateProducts(
                      //   val?.products,
                      //   action.payload.product,
                      //   "rebate",
                      //   parseFloat(action.payload.rebate)
                      // ),
                    };
                  }
                  return val;
                }),
              };
            }
            return member;
          }),
        };
      case "UPDATE_MEMBER_COMMISSION_PRODUCT":
        return {
          ...state,
          members: state.members.map((member) => {
            if (member.customerId === action.payload.customerId) {
              return {
                ...member,
                value: member.values.map((val) => {
                  if (val.accountTypeId === action.payload.accountTypeId) {
                    return {
                      ...val,
                      // products: calculateProducts(
                      //   val?.products,
                      //   action.payload.product,
                      //   "commission",
                      //   parseFloat(action.payload.commission)
                      // ),
                    };
                  }
                  return val;
                }),
              };
            }
            return member;
          }),
        };
      case "UPDATE_MEMBER_PAMM_PROFIT_SHARE":
        return {
          ...state,
          members: state.members.map((member) => {
            if (member.customerId === action.payload.customerId) {
              return {
                ...member,
                values: member.values.map((val) => {
                  if (val.accountTypeId === action.payload.accountTypeId) {
                    return {
                      ...val,
                      pamProfitShare: parseFloat(action.payload.pamProfitShare),
                    };
                  }
                  return val;
                }),
              };
            }
            return member;
          }),
        };
      default:
        return state;
    }
  }, initState);

  const { title, totals, members } = payload;

  const accountTypes = useMemo(()=>{
    return allAccountTypes.filter(accType => accType.type.toLowerCase() !== "demo");
  }, [allAccountTypes]);

  const productsModel = useMemo(() => {
    if (products) {
      const model = {};
      products.forEach((product) => {
        model[product] = {
          rebate: 0,
          commission: 0,
        };
      });
      return model;
    }
    return {};
  }, [products]);

  useEffect(() => {
    if (accountTypes?.length > 0) {
      const totals = [];
      accountTypes.forEach((acc) => {
        totals.push({
          accountTypeId: acc?._id,
          rebate: null,
          commission: null,
        });
      });
      dispatcher({
        type: "INIT_TOTALS",
        payload: totals,
      });
    }
  }, [accountTypes]);


  useEffect(() => {
    if (parents?.length > 0) {
      const members = [];
      parents?.[0].forEach((parent) => {
        members.push({
          customerId: parent?._id,

          level: parent?.level,
          values: accountTypes?.map((acc) => {
            if (acc?.type?.toUpperCase() === "INVESTOR") {
              return {
                accountTypeId: acc?._id,
                pamProfitShare: 0,
              };
            } else {
              return {
                accountTypeId: acc?._id,
                rebate: 0,
                commission: 0,
                products: productsModel,
              };
            }
          }),
        });
      });
      dispatcher({
        type: "INIT_MEMBERS",
        payload: members,
      });
    }
  }, [parents, accountTypes, productsModel]);

  useEffect(() => {
    clientId && dispatch(fetchIbParents({ clientId }));
  }, [clientId]);

  useEffect(() => {
    return () => {
      dispatcher({
        type: "RESET",
      });
    };
  }, []);

  useEffect(() => {
    if (totals && aggrementTotalsItem.current?.length === 0) {
      const itemsData = [];
      totals?.forEach((ele) => {
        itemsData?.push(AGGREMENT_TOTAL_TYPE(ele));
        aggrementTotalsItem.current = itemsData;
      });
    }
  }, [totals]);

  useEffect(() => {
    if (members?.length > 0 && (membersItems.current?.[0]?.values?.length ?? 0) === 0) {
      membersItems.current = JSON.parse(JSON.stringify(members));
    }
  }, [members]);

  useEffect(() => {
    return () => {
      setIsAgreementValid(false);
      dispatcher({
        type: "RESET",
      });
    };
  }, []);


  useEffect(() => {
    !submitting && show && setIsAgreementValid(false) && toggle();
  }, [submitting]);

  useEffect(() => {
    !submitting && show && toggle();
  }, [clearingCounter]);

  useEffect(() => {
    setSubmissionFailure(false);
  }, []);

  function changeAggrementItem(index, key, value) {
    const itemsModified = JSON.parse(JSON.stringify(aggrementTotalsItem.current));
    itemsModified[index][key] = value;
    aggrementTotalsItem.current = itemsModified;
  }

  function changeMembersItem(index, key, value, valueIndex) {
    const membersModified = JSON.parse(JSON.stringify(membersItems.current));
    membersModified[index].values[valueIndex][key] = value;
    membersItems.current = membersModified;

  }

  function changeMembersProductItem(index, key, value, valueIndex, productKey) {
    const membersModified = JSON.parse(JSON.stringify(membersItems.current));
    membersModified[index].values[valueIndex].products[`${productKey}`][key] = value;
    membersItems.current = membersModified;
  }

  const validateForm = () => {
    if (!title) {
      setIsAgreementValid(false);
      setValidationErrorMessage("Please Enter Agreement Title");
      return false;
    }
    let totals = aggrementTotalsItem.current;
    let members = membersItems.current;
    if (totals?.length > 0) {
      // Check for null fields first
      let isValid = true;
      totals.forEach((total) => {
        if (total?.commission === null || total?.rebate === null) {
          isValid = false;
        }
      });
      if (!isValid) {
        setIsAgreementValid(false);
        setValidationErrorMessage("Please Enter Commission and Rebate");
        return false;
      }
    }
    let isMemberValuesValid = true;
    let isSumValid = true;
    let totalsSum = new Map();
    if (members?.length > 0 && totals?.length > 0) {
      // 1st validation is null
      // 2nd validation is sum of members rebate and commission should be less than or equal to totals
      // 3rd validation should be for each members.value's rebate and commission should not be null and the products rebate and commission should not be null and the sum of products rebate and commission should be less than or equal to members rebate and commission
      for (let i = 0;i < totals.length;i++) {
        totalsSum.set(totals[i].accountTypeId, {
          commission: totals[i].commission,
          rebate: totals[i].rebate,
        });
      }
      for (let i = 0;i < members.length;i++) {
        const member = members[i];
        for (let j = 0; j < member.values.length; j++) {
          const value = member.values[j];
          if (value?.rebate === null || value?.commission === null) {
            isMemberValuesValid = false;
            break;
          } else {
            const total = totalsSum.get(value.accountTypeId);
            // subtract the member values from totals if total becomes less than 0 then it is invalid
            if (total) {
              const newTotal = {
                commission: total.commission - value.commission,
                rebate: total.rebate - value.rebate,
              };
              if (newTotal.commission < 0 || newTotal.rebate < 0) {
                isSumValid = false;
                break;
              } else {
                totalsSum.set(value.accountTypeId, newTotal);
              }
            }
          }
        }
        if (!isMemberValuesValid) {
          setIsAgreementValid(false);
          setValidationErrorMessage("Please Enter Commission and Rebate for each member");
          break;
        }
        if (!isSumValid) {
          setIsAgreementValid(false);
          setValidationErrorMessage("Sum of Commission and Rebate for each member should be less than or equal to totals");
          break;
        }
      }

    }
    isMemberValuesValid && isSumValid && setIsAgreementValid(true) & setValidationErrorMessage("");
    return true;
  };

  return (
    <Modal
      isOpen={show}
      centered={true}
      scrollable={true}
      onClosed={() => {
        setSubmissionFailure(false);
      }}
    >
      <ModalHeader toggle={()=>{
        dispatcher({
          type: "RESET",
        });
        toggle();
      }} tag="h4">
        {t("New Shared IB Agreement")}
      </ModalHeader>
      <ModalBody>
        {submissionFailure ? (
          <div
            style={{
              display: "block",
              color: "white",
              marginBottom: 10,
              padding: 10,
              borderRadius: 5,
              backgroundColor: "#f65854",
            }}
            className="formValidationErrMessage"
          >
            {"Invalid values found. Please check the form again!"}
          </div>
        ) : null}
        <Form
          onSubmit={(e)=>{
            e.preventDefault();
            setSubmissionFailure(false);
            const valid = validateForm();
            const data = {
              title: title,
              totals: aggrementTotalsItem.current,
              members: membersItems.current,
            };
            if (valid) {
              dispatch(
                createSharedIbAgreement(data)
              );
            }
          }}
        >
          <FormGroup>
            <Label for="title">
              {t("Agreement name")}
            </Label>
            <Input
              name={"title"}
              id="title"
              className="mb-3"
              type="text"
              value={title}
              onChange={(e) => {
                dispatcher({
                  type: "UPDATE_TITLE",
                  payload: e.target.value,
                });
              }}
            />
          </FormGroup>
          <Row className="justify-content-start">
            <Col md="3">{t("Type")}</Col>
            <Col>{t("Total Rebate")}</Col>
            <Col>{t("Total Commission")}</Col>
          </Row>
          {aggrementTotalsItem.current?.length > 0 &&
            <List
              height={300}
              itemCount={totals?.length}
              itemSize={60}
              itemData={{
                allAccountTypes,
                totals,
                dispatcher,
                aggrementTotalsItem: aggrementTotalsItem.current,
                changeAggrementItem,
              }}
              width="100%"
            >
              {RowAggreementTotalsItem}
            </List>}
          <hr className="my-3" />
          <Accordion className="mb-3" alwaysOpen>
            {members &&
              members
                .sort((a, b) => (a.level > b.level ? 1 : -1))
                .map((member, memberIdx) => {
                  const customer = parents?.[0]?.find((p) => p._id === member.customerId);
                  return <>
                    <Accordion.Item key={member.customerId} eventKey={memberIdx}>
                      <Accordion.Header>
                        {customer?.firstName} {customer?.lastName}
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="my-1" alwaysOpen>
                          <List
                            height={300}
                            itemCount={member?.values?.length}
                            itemSize={500}
                            itemData={{
                              allAccountTypes,
                              members,
                              dispatcher,
                              products,
                              markups,
                              membersItems: membersItems.current,
                              changeMembersItem,
                              changeMembersProductItem,
                            }}
                            width="100%"
                          >
                            {(data)=>(
                              <RowMemberValueItemMemo
                                data={data}
                                member={member}
                                memberIdx={memberIdx}
                                changeMembersItem={changeMembersItem}
                                changeMembersProductItem={changeMembersProductItem}
                                membersItems={membersItems}
                                markups={markups}
                                products={products}
                              />
                            )}
                          </List>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>;
                })}
          </Accordion>
          {!isAgreementValid && validationErrorMessage && <Alert color="danger">{validationErrorMessage}</Alert>}
          {submitting ? <Spinner size="sm" color="primary" /> :
            <Button type="submit" disabled={submitting}>
              {t("Submit")}
            </Button>}
        </Form>
      </ModalBody>

    </Modal>

  );
};

export default AddSharedIbModal;
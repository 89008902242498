import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Row, Col, Input 
}  from "reactstrap";
const InvestorForm = ({ accIdx,  agreementName, memberIdx, membersItems, changeMembersItem }) => {
  const { t } = useTranslation();
  const [membersItemsState, setMembersItemsState] = useState(membersItems.current);
  return (
    <>
      <Row className="justify-content-center mb-2 fw-bold gx-0">
        <Col md="12" className="text-center">
          {agreementName}
        </Col>
      </Row>
      <Row>
        <Col md="3" className="text-center">
          {t("PAMM Profit Share")} %
        </Col>
        <Col md="9" className="text-center">
          <Input
            name={`members[${memberIdx}].values[${accIdx}].pamProfitShare`}
            value={`${membersItemsState?.[memberIdx]?.values?.[accIdx]?.pamProfitShare}`}
            type="text"
            errorMessage={t("Invalid Profit Share!")}
            validate={{
              required: { value: true },
              min: { value: 0 }
            }}
            onChange={(e) => {
              changeMembersItem(memberIdx, "pamProfitShare", e.target.value, accIdx);
              setMembersItemsState((state) => {
                const newState = [...state];
                newState[memberIdx].values[accIdx].pamProfitShare = e.target.value;
                return newState;
              });
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default memo(InvestorForm);